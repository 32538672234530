document.addEventListener('DOMContentLoaded', () => {
    const cookiePopup = document.querySelector('.js-popup-cookie');
    const coolieBtnClose = document.querySelector('.js-cookie-close');

    let cookie = localStorage.getItem("cookie");

    coolieBtnClose.addEventListener('click', () => {
        cookiePopup.classList.remove('js-open');
        localStorage.setItem('cookie', 'close');
    })

    if (cookie !== 'close') {
        cookiePopup.classList.add('js-open');
    }
})