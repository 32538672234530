document.addEventListener("DOMContentLoaded", function() {
    const burger = document.querySelector(".js-burger");
    const nav = document.querySelector(".nav-mobile");
    const navCloseList = document.querySelectorAll(".js-nav-mobile-close");
    const html = document.querySelector("html");
    navCloseList.forEach(navClose => {
        navClose.addEventListener("click", function() {
            nav.classList.remove("js-open");
            html.classList.remove("js-modal-open");

        })
    })
    if (burger) {
        burger.addEventListener("click", function() {
            nav.classList.add("js-open");
            html.classList.add("js-modal-open");
        })
    }

    if (nav) {
        const navLinkList = nav.querySelectorAll("a");
        if (navLinkList) {
            navLinkList.forEach(navLink => {
                if (navLink.href.includes('#')) {
                    navLink.addEventListener("click", function() {
                        nav.classList.remove("js-open");
                        html.classList.remove("js-modal-open");
                    })
                }
            })
        }
    }
})