const iconClose = `<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1.83594L6.00081 6.41594L11 1.83594" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
const iconMinus = `<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1.83594L6.00081 6.41594L11 1.83594" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
document.addEventListener('DOMContentLoaded', function(){
    const accordionsList = document.querySelectorAll('.accordion');
    if (accordionsList){
        function resetHeight(content,height) {
            content.style.height = `${height}px`;
            setTimeout(() => {
                content.style.height = `auto`
                height = content.offsetHeight;
                content.style.height = `${height}px`;
            }, 300);
        }
        accordionsList.forEach(accordion => {
            const accordionList = accordion.querySelectorAll('.accordion__item');
            accordionList.forEach((item,index) => {
                const content = item.querySelector('.accordion__content');

                item.classList.add('js-open');
                let height = content.offsetHeight;
                item.classList.remove('js-open');

                const icon = item.querySelector('.accordion__icon');
                const headerList = item.querySelectorAll('.accordion__header');
                headerList.forEach(header => {
                    header.addEventListener('click', () => {
                        item.classList.toggle('js-open');

                        if (item.classList.contains('js-open')) {
                            resetHeight(content,height)
                            // icon.innerHTML = iconMinus;
                        } else {
                            content.style.height = `${0}px`;
                            // icon.innerHTML = iconClose;
                        }
                    })
                })


                if (index === 0) {
                    item.classList.add('js-open');
                    resetHeight(content,height)
                    // icon.innerHTML = iconClose;
                } else {
                    // icon.innerHTML = iconClose;
                }
            })
        })
    }
})